/* Built In Imports */
/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain } from '@components/Utility/utils';

/* Services */

/**
 * Renders the Technologies for wellbeing component
 * @param {Object} sectionData - content from api
 * @param {Object} languageData - language
 * @param {string} region - region and country
 * @returns {ReactElement} Technologies for wellbeing component
 */
const TechnologiesForWellbeing = ({ sectionData, languageData, region }) => {
  return (
    <>
      <Box color="#28231e" textAlign="center" pb="40px">
        <Box
          fontSize="30px"
          padding={{ base: '44px 0 0 0', md: '0 0 0 0', lg: '44px 0 0 0' }}
          fontFamily="FedraSansStd-A-medium"
        >
          {/* {sectionData.title} */}
          Technologies for Wellbeing
        </Box>
        <Box
          fontFamily="FedraSerifAStdBook"
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
          justifyContent={{ base: 'center', md: 'flex-center' }}
          maxWidth={{ md: '738px', lg: '1200px' }}
          mx="auto"
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        >
          {sectionData?.sectionContent?.map((item, index) => {
            return (
              <Box
                key={index}
                p={{ base: '7.5px', md: '15px' }}
                boxSize="border-box"
                flexDirection={{ base: 'column', md: 'row' }}
                w={{ base: '317px', md: '360px', lg: '50%' }}
                _last={{
                  marginRight: '0',
                }}
                mb={{ base: '20px', md: '0' }}
              >
                <Box
                  boxShadow="0 12px 15px -6px rgb(0 0 0 / 30%)"
                  mb="10px"
                  minH={{ base: '302px', md: '270px' }}
                >
                  <LazyLoadImageComponent
                    src={item.image?.url}
                    alt={item.image?.alt}
                    title={item.image?.title}
                  />
                </Box>
                <Box height={{ md: '100px' }}>
                  <Text
                    fontSize="18px"
                    maxHeight="100px"
                    padding="18px"
                    lineHeight="22px"
                    mb="16px"
                  >
                    {item.title}
                  </Text>
                </Box>
                <Link
                  borderRadius="3px"
                  bgColor="#cf4520"
                  fontWeight="500"
                  href={refineUrlDomain(item.ctaLink, region, languageData)}
                  textDecoration="none"
                  color="#faf7f0"
                  padding="5px 25px"
                  height="auto"
                  fontSize="14px"
                  lineHeight="1.6"
                  border="none !important"
                  fontFamily="FedraSansStd-book"
                  _hover={{
                    bgColor: '#000054',
                    color: 'white',
                  }}
                >
                  {item.ctaName}
                </Link>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default TechnologiesForWellbeing;
