/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Image, Link } from '@chakra-ui/react';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Renders the Banner Content MMM component
 *
 * @param {string} region - country
 * @param {string} lang - language
 * @returns {ReactElement} Banner Content MMM component
 */

const BannerContentMMM = ({ region, lang }) => {
  const settings = {
    responsive: [
      {
        breakpoint: 10000, // to hide slider for desktop and ipad and other large screen resolutions
        settings: 'unslick',
      },
      {
        breakpoint: 599,
        settings: {
          autoplay: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          mobileFirst: false,
        },
      },
    ],
  };

  return (
    <>
      <Box display={{ base: 'block', md: 'none' }}>
        <Box
          display={{ base: 'block', md: 'flex', lg: 'flex' }}
          justifyContent="center"
          textAlign="center"
          w={{ base: '95%', md: 'auto' }}
          margin={{ base: '0 auto 35px', md: '0px auto 0' }}
          padding={{ base: '18px 0 0', md: '0' }}
          minH={{ base: '472px', sm: 'unset' }}
        >
          <Slider {...settings} className="mmm-intro-slider">
            <Box
              w={{ base: '100%', sm: '33%', md: '361px' }}
              float={{ base: 'none', sm: 'left', md: 'none' }}
              maxW={{ base: '100%', md: '361px' }}
              boxShadow="0 18px 16px -5px rgb(0 0 0 / 30%)"
              margin={{ base: '0 auto', md: '0' }}
              overflow="hidden"
              position="relative"
              minHeight={{ base: '455px', sm: 'unset' }}
              height={{ base: 'auto', md: '466px' }}
              background="#e9e9e9"
              className="mmm-intro"
            >
              <NextLink
                href={refineUrlDomain('/sadhguru/man', region, lang)}
                passHref
                legacyBehavior
              >
                <Link>
                  <LazyLoadImageComponent
                    src={`${config.staticPath}/assets/images/isha-home-man_new.jpg`}
                    transform="scale(1)"
                    transition=".3s ease-in-out"
                    sx={{
                      '.mmm-intro:hover &': {
                        transform: 'scale(1.15)',
                      },
                    }}
                    alt="Sadhguru kneeling down to drink water from a river"
                    title=""
                  />
                  <Box
                    position="absolute"
                    backgroundColor="rgba(45, 45, 45, 0.31)"
                    bottom="-1px"
                    right="0%"
                    left="0%"
                    clipPath="polygon(0 0, 100% 20%, 100% 100%, 0% 100%)"
                    paddingBottom="15px"
                    paddingTop="18px"
                  >
                    <Box
                      as="span"
                      fontSize={{ base: '5vw', md: '4vw' }}
                      textAlign="left"
                      color="#faf7f0"
                      textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                    >
                      Man
                      <LazyLoadImageComponent
                        src={`${config.staticPath}/assets/images/right-arrow.svg`}
                        marginLeft="8.1%"
                        alt="Arrow"
                        title=""
                        width="15px"
                        filter="brightness(100)"
                        transform="scale(1)"
                        transition=".3s ease-in-out"
                        sx={{
                          '.mmm-intro:hover &': {
                            transform: 'scale(1.15)',
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Link>
              </NextLink>
            </Box>
            <Box
              w={{ base: '100%', sm: '33%', md: '361px' }}
              float={{ base: 'none', sm: 'left', md: 'none' }}
              boxShadow="0 18px 16px -5px rgb(0 0 0 / 30%)"
              margin={{ base: '0 auto', md: '0' }}
              overflow="hidden"
              position="relative"
              height={{ base: 'auto', md: '483px' }}
              background="#e9e9e9"
              minH={{ base: '472px', sm: 'unset' }}
              className="mmm-intro"
            >
              <NextLink
                href={refineUrlDomain('/sadhguru/mystic', region, lang)}
                passHref
                legacyBehavior
              >
                <Link>
                  <LazyLoadImageComponent
                    src={`${config.staticPath}/assets/images/isha-home-mystic_new.jpg`}
                    transform="scale(1)"
                    transition=".3s ease-in-out"
                    sx={{
                      '.mmm-intro:hover &': {
                        transform: 'scale(1.15)',
                      },
                    }}
                    alt="Close-up of Sadhguru´s face with turban."
                    title=""
                  />
                  <Box
                    position="absolute"
                    backgroundColor="rgba(116, 70, 37, 0.31)"
                    bottom="-1px"
                    right="0%"
                    left="0%"
                    clipPath="polygon(0 20%, 100% 0, 100% 100%, 0% 100%)"
                    paddingBottom="15px"
                    paddingTop="18px"
                  >
                    <Box
                      as="span"
                      fontSize={{ base: '5vw', md: '4vw' }}
                      textAlign="left"
                      color="#faf7f0"
                      textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                    >
                      Mystic
                      <Image
                        loading="lazy"
                        src={`${config.staticPath}/assets/images/right-arrow.svg`}
                        marginLeft="8.1%"
                        alt="Arrow"
                        title=""
                        width="15px"
                        filter="brightness(100)"
                        transform="scale(1)"
                        transition=".3s ease-in-out"
                        sx={{
                          '.mmm-intro:hover &': {
                            transform: 'scale(1.15)',
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Link>
              </NextLink>
            </Box>
            <Box
              w={{ base: '100%', sm: '33%', md: '361px' }}
              float={{ base: 'none', sm: 'left', md: 'none' }}
              animation={{ base: 'none', md: 'inherit' }}
              boxShadow="0 18px 16px -5px rgb(0 0 0 / 30%)"
              margin={{ base: '0 auto', md: '0' }}
              overflow="hidden"
              position="relative"
              height={{ base: 'auto', md: '423px' }}
              background="#e9e9e9"
              minH={{ base: '414px', sm: 'unset' }}
              className="mmm-intro"
            >
              <NextLink
                href={refineUrlDomain('/sadhguru/mission', region, lang)}
                passHref
                legacyBehavior
              >
                <Link>
                  <LazyLoadImageComponent
                    src={`${config.staticPath}/assets/images/isha-home-mission_new.jpg`}
                    transform="scale(1)"
                    transition=".3s ease-in-out"
                    sx={{
                      '.mmm-intro:hover &': {
                        transform: 'scale(1.15)',
                      },
                    }}
                    alt="Sadhguru holding is hands together above his head, greeting a huge crowd, seen from the back."
                    title=""
                  />
                  <Box
                    position="absolute"
                    backgroundColor="rgba(62, 41, 32, 0.31)"
                    bottom="-2px"
                    right="0%"
                    left="0%"
                    clipPath="polygon(0 0, 100% 20%, 100% 100%, 0% 100%)"
                    paddingBottom="15px"
                    paddingTop="18px"
                  >
                    <Box
                      as="span"
                      fontSize={{ base: '5vw', md: '4vw' }}
                      textAlign="left"
                      color="#faf7f0"
                      textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                    >
                      Mission
                      <LazyLoadImageComponent
                        src={`${config.staticPath}/assets/images/right-arrow.svg`}
                        marginLeft="8.1%"
                        width="15px"
                        alt="Arrow"
                        title=""
                        filter="brightness(100)"
                        transform="scale(1)"
                        transition=".3s ease-in-out"
                        sx={{
                          '.mmm-intro:hover &': {
                            transform: 'scale(1.15)',
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Link>
              </NextLink>
            </Box>
          </Slider>
        </Box>
      </Box>
      <Box display={{ base: 'none', md: 'block' }}>
        <Box
          display={{ base: 'block', md: 'flex', lg: 'flex' }}
          justifyContent="center"
          textAlign="center"
          w={{ base: '95%', md: 'auto' }}
          margin={{ base: '0 auto 35px', md: '0px auto 0' }}
          padding={{ base: '18px 0 0', md: '0' }}
          alignItems="start"
          minH="326px"
        >
          <Box
            w={{ base: '100%', sm: '33%', md: '361px' }}
            float={{ base: 'none', sm: 'left', md: 'none' }}
            maxW={{ base: '100%', md: '361px' }}
            boxShadow="0 18px 16px -5px rgb(0 0 0 / 30%)"
            margin={{ base: '0 auto', md: '0' }}
            overflow="hidden"
            position="relative"
            minHeight={{ base: '455px', sm: 'unset' }}
            height={{ base: '100%', xl: '466px' }}
            background="#e9e9e9"
            className="mmm-intro"
          >
            <NextLink
              href={refineUrlDomain('/sadhguru/man', region, lang)}
              passHref
              legacyBehavior
            >
              <Link>
                <Box
                  transform="scale(1)"
                  transition=".3s ease-in-out"
                  sx={{
                    '.mmm-intro:hover &': {
                      transform: 'scale(1.15)',
                    },
                  }}
                >
                  <LazyLoadImageComponent
                    src={`${config.staticPath}/assets/images/isha-home-man_new.jpg`}
                    alt="Sadhguru kneeling down to drink water from a river"
                    title=""
                  />
                </Box>
                <Box
                  position="absolute"
                  backgroundColor="rgba(45, 45, 45, 0.31)"
                  bottom="-1px"
                  right="0%"
                  left="0%"
                  clipPath="polygon(0 0, 100% 20%, 100% 100%, 0% 100%)"
                  paddingBottom="15px"
                  paddingTop="18px"
                >
                  <Box
                    as="span"
                    fontSize="300%"
                    textAlign="left"
                    color="#faf7f0"
                    textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                  >
                    Man
                    <LazyLoadImageComponent
                      src={`${config.staticPath}/assets/images/right-arrow.svg`}
                      marginLeft="8.1%"
                      width="15px"
                      filter="brightness(100)"
                      transform="scale(1)"
                      transition=".3s ease-in-out"
                      sx={{
                        '.mmm-intro:hover &': {
                          transform: 'scale(1.15)',
                        },
                      }}
                      alt="Arrow"
                      title=""
                    />
                  </Box>
                </Box>
              </Link>
            </NextLink>
          </Box>
          <Box
            w={{ base: '100%', sm: '33%', md: '361px' }}
            float={{ base: 'none', sm: 'left', md: 'none' }}
            boxShadow="0 18px 16px -5px rgb(0 0 0 / 30%)"
            margin={{ base: '0 auto', md: '0' }}
            overflow="hidden"
            position="relative"
            height={{ base: 'auto', xl: '483px' }}
            background="#e9e9e9"
            className="mmm-intro"
          >
            <NextLink
              href={refineUrlDomain('/sadhguru/mystic', region, lang)}
              passHref
              legacyBehavior
            >
              <Link>
                <Box
                  transform="scale(1)"
                  transition=".3s ease-in-out"
                  sx={{
                    '.mmm-intro:hover &': {
                      transform: 'scale(1.15)',
                    },
                  }}
                >
                  <LazyLoadImageComponent
                    src={`${config.staticPath}/assets/images/isha-home-mystic_new.jpg`}
                    alt="Close-up of Sadhguru´s face with turban."
                    title=""
                  />
                </Box>
                <Box
                  position="absolute"
                  backgroundColor="rgba(116, 70, 37, 0.31)"
                  bottom="-1px"
                  right="0%"
                  left="0%"
                  clipPath="polygon(0 20%, 100% 0, 100% 100%, 0% 100%)"
                  paddingBottom="15px"
                  paddingTop="18px"
                >
                  <Box
                    as="span"
                    fontSize="300%"
                    textAlign="left"
                    color="#faf7f0"
                    textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                  >
                    Mystic
                    <Image
                      loading="lazy"
                      src={`${config.staticPath}/assets/images/right-arrow.svg`}
                      marginLeft="8.1%"
                      width="15px"
                      filter="brightness(100)"
                      transform="scale(1)"
                      transition=".3s ease-in-out"
                      sx={{
                        '.mmm-intro:hover &': {
                          transform: 'scale(1.15)',
                        },
                      }}
                      alt="Arrow"
                      title=""
                    />
                  </Box>
                </Box>
              </Link>
            </NextLink>
          </Box>
          <Box
            w={{ base: '100%', sm: '33%', md: '361px' }}
            float={{ base: 'none', sm: 'left', md: 'none' }}
            animation={{ base: 'none', md: 'inherit' }}
            boxShadow="0 18px 16px -5px rgb(0 0 0 / 30%)"
            margin={{ base: '0 auto', md: '0' }}
            overflow="hidden"
            position="relative"
            height={{ base: 'auto', xl: '423px' }}
            background="#e9e9e9"
            className="mmm-intro"
          >
            <NextLink
              href={refineUrlDomain('/sadhguru/mission', region, lang)}
              passHref
              legacyBehavior
            >
              <Link>
                <Box
                  transform="scale(1)"
                  transition=".3s ease-in-out"
                  sx={{
                    '.mmm-intro:hover &': {
                      transform: 'scale(1.15)',
                    },
                  }}
                >
                  <LazyLoadImageComponent
                    src={`${config.staticPath}/assets/images/isha-home-mission_new.jpg`}
                    alt="Sadhguru holding is hands together above his head, greeting a huge crowd, seen from the back."
                    title=""
                  />
                </Box>
                <Box
                  position="absolute"
                  backgroundColor="rgba(62, 41, 32, 0.31)"
                  bottom="-2px"
                  right="0%"
                  left="0%"
                  clipPath="polygon(0 0, 100% 20%, 100% 100%, 0% 100%)"
                  paddingBottom="15px"
                  paddingTop="18px"
                >
                  <Box
                    as="span"
                    fontSize="300%"
                    textAlign="left"
                    color="#faf7f0"
                    textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                  >
                    Mission
                    <LazyLoadImageComponent
                      src={`${config.staticPath}/assets/images/right-arrow.svg`}
                      marginLeft="8.1%"
                      width="15px"
                      filter="brightness(100)"
                      transform="scale(1)"
                      transition=".3s ease-in-out"
                      sx={{
                        '.mmm-intro:hover &': {
                          transform: 'scale(1.15)',
                        },
                      }}
                      alt="Arrow"
                      title=""
                    />
                  </Box>
                </Box>
              </Link>
            </NextLink>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BannerContentMMM;