/* Built In Imports */
/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * Renders about Isha Outreach component
 *
 * @param {object} outreachContent - content from api
 * @param {string} languageData - language
 * @param {string} region - country
 * @returns {ReactElement} - Isha Outreach Component
 */

const IshaOutreach = ({ outreachContent, languageData, region }) => {
    // console.log("Out reach content", outreachContent, region)
    const sectionItemsOrder = orderNumber => {
        // if (region === 'us') {
        //   orderNumber = orderNumber - 1;
        // }
        return orderNumber;
    };

    return (
        <>
            <Box
                padding={{ base: '86px 0 0', md: '0 2.2% 0', lg: '0 2.2% 0' }}
                marginTop={"80px"}
                textAlign="center"
                background={`url('${config.staticPath}/assets/images/home-outreach-texture.jpg') no-repeat`}
                backgroundPosition="0% -8.5%"
            >
                {/* Head section  */}
                <Box display="flex" justifyContent="center">
                    <Box
                        background={`url(${outreachContent.sectionContent[0].image?.url}) no-repeat`}
                        backgroundSize="cover"
                        width="32%"
                        minHeight={{ base: '521px', md: '400px', lg: '521px' }}
                        display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
                        textAlign="center"
                        position="relative"
                    >
                        <Text
                            alignSelf="flex-end"
                            width="100%"
                            position="absolute"
                            bottom="10%"
                            left="0"
                            textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                        >
                            {outreachContent.sectionContent[0].initiative}{' '}
                        </Text>
                    </Box>
                    <Box
                        background={`url(${outreachContent.sectionContent[1].image?.url}) no-repeat`}
                        backgroundSize="cover"
                        width={{ base: '100%', sm: '80%', md: '32%' }}
                        minHeight={{ base: '521px', sm: '609px', md: '400px', lg: '521px' }}
                        display="flex"
                        textAlign="center"
                        padding="0 27px"
                        position="relative"
                        justifyContent="center"
                        mt={{ base: 'unset', sm: '-50px', md: 'unset' }}
                    >
                        <Box alignSelf="center">
                            <Text
                                as="h2"
                                fontSize={{ base: '34px', sm: '45px', md: '35px', lg: '61px' }}
                                color="#28231e"
                                textShadow="0 0 17px #ffffff"
                                lineHeight="0.8"
                            >
                                {outreachContent.sectionContent[1].title}
                            </Text>
                            <Text
                                fontSize={{ base: '30px', sm: '24px', md: '30px' }}
                                lineHeight="1.4"
                            >
                                {outreachContent.sectionContent[1].subHeading}
                            </Text>
                            <Link
                                fontSize="13px"
                                border="none"
                                fontWeight="500"
                                padding="6px 12px"
                                borderRadius="3px"
                                height="auto"
                                marginTop="10px"
                                display="inline-block"
                                lineHeight="unset"
                                background="#cf4520"
                                color="#ffffff"
                                href={
                                    '/' + region + '/' + languageData + outreachContent.sectionContent[1].ctaLink
                                }
                                _hover={{
                                    border: 'none',
                                    background: '#000054',
                                }}
                            >
                                {outreachContent.sectionContent[1].ctaName}
                            </Link>
                            <Text
                                alignSelf="flex-end"
                                width="100%"
                                position="absolute"
                                bottom="10%"
                                left="0"
                                textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                                display={{ base: 'none', md: 'block' }}
                            >
                                {outreachContent.sectionContent[1].initiative}
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        background={`url(${outreachContent.sectionContent[2].image?.url}) no-repeat`}
                        backgroundSize="cover"
                        width="32%"
                        minHeight={{ base: '521px', md: '400px', lg: '521px' }}
                        display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
                        textAlign="center"
                        position="relative"
                    >
                        <Text
                            alignSelf="flex-end"
                            width="100%"
                            position="absolute"
                            bottom="10%"
                            left="0"
                            textShadow="0 1px 2px rgb(0 0 0 / 60%)"
                        >
                            {outreachContent.sectionContent[2].initiative}
                        </Text>
                    </Box>
                </Box>

            </Box>
        </>
    );
};

export default IshaOutreach;
