/* Built In Imports */
/* External Imports */
// import { Box, Image, Link } from '@chakra-ui/react';
// import Slider from 'react-slick';
// import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Internal Imports */
/* Components */
import BannerContentMMM from '@components/HeaderBanner/BannerContentMMM';

/* Services */

/**
 * Renders the Technologies for Home page MMM banner component
 *
 * @param {string} lang - language
 * @param {string} region - region and country
 * @returns {ReactElement} Home page MMM banner component
 */

const HomeBannerMMM = ({ region, lang }) => {
  // const settings = {
  //   responsive: [
  //     {
  //       breakpoint: 10000, // to hide slider for desktop and ipad and other large screen resolutions
  //       settings: 'unslick',
  //     },
  //     {
  //       breakpoint: 599,
  //       settings: {
  //         autoplay: false,
  //         speed: 1000,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         dots: true,
  //         arrows: false,
  //         mobileFirst: false,
  //       },
  //     },
  //   ],
  // };

  return (
    <BannerContentMMM
      region={region}
      lang={lang}
    />
  );
};

export default HomeBannerMMM;
