/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
// import { Image } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';
/* Services */

/**
 * Renders Share Icon Component 
 *
 * @param {string} url - Link 
 * @returns {ReactElement} - Share Icon Component 
 */
export default function ShareIcon({ url }) {
  const [isShareSlideShow, setIsShareSlideShow] = useState(false);

  const shareHandler = () => {
    setIsShareSlideShow(!isShareSlideShow);
  };

  return (
    <Box display="flex">
      <Box float="left" p="0 0.5vw">
        <Box
          height="24px"
          width="24px"
          p="0 0.5vw"
          float="right"
          cursor="pointer"
          background={`url('${config.imgPath}/d/46272/1663579985-share.svg')`}
          onClick={shareHandler}
        ></Box>
        <Box
          overflow="hidden"
          background="#f9f6f1"
          position="absolute"
          width="195px"
          right={{ base: '45%', md: '380px' }}
          // ml="-274px"
          // mr="57px"
          mt="-7px"
          display={isShareSlideShow ? 'block' : 'none'}
        >
          <Box height="30px" display="flex">
            <Link
              target="_blank"
              href={`https://www.addtoany.com/add_to/whatsapp?linkurl=${config.BASE_PATH}${url}`}
              rel="nofollow noopener"
            >
              <Text
                as="span"
                display="block"
                m="5px 6px"
                cursor="pointer"
                height="27px"
                width="25px"
                background={`url('${config.imgPath}/d/46272/1663580397-whatsapp.svg')`}
                backgroundRepeat="no-repeat"
                _hover={{
                  background: `url('${config.imgPath}/d/46272/1663580395-whatsapp1.svg')`,
                }}
              ></Text>
            </Link>
            <Link
              target="_blank"
              href={`https://www.addtoany.com/add_to/facebook?linkurl=${config.BASE_PATH}${url}`}
              rel="nofollow noopener"
            >
              <Text
                as="span"
                display="block"
                m="5px 6px"
                cursor="pointer"
                height="27px"
                width="25px"
                background={`url('${config.imgPath}/d/46272/1663580393-facebook.svg')`}
                backgroundRepeat="no-repeat"
                _hover={{
                  background: `url('${config.imgPath}/d/46272/1663580389-facebook1.svg')`,
                }}
              ></Text>
            </Link>
            <Link
              target="_blank"
              href={`https://www.addtoany.com/add_to/twitter?linkurl=${config.BASE_PATH}${url}`}
              rel="nofollow noopener"
            >
              <Text
                as="span"
                display="block"
                m="5px 6px"
                cursor="pointer"
                height="27px"
                width="25px"
                background={`url('${config.imgPath}/d/46272/1663580385-twitter.svg')`}
                backgroundRepeat="no-repeat"
                _hover={{
                  background: `url('${config.imgPath}/d/46272/1663580381-twitter1.svg')`,
                }}
              ></Text>
            </Link>
            <Link
              target="_blank"
              href={`https://www.addtoany.com/add_to/linkedin?linkurl=${config.BASE_PATH}${url}`}
              rel="nofollow noopener"
            >
              <Text
                as="span"
                display="block"
                m="5px 6px"
                cursor="pointer"
                height="27px"
                width="25px"
                background={`url('${config.imgPath}/d/46272/1663580377-linkedin.svg')`}
                backgroundRepeat="no-repeat"
                _hover={{
                  background: `url('${config.imgPath}/d/46272/1663580369-linkedin1.svg')`,
                }}
              ></Text>
            </Link>
            <Link
              target="_blank"
              href={`https://www.addtoany.com/add_to/email?linkurl=${config.BASE_PATH}${url}`}
              rel="nofollow noopener"
            >
              <Text
                as="span"
                display="block"
                m="5px 6px"
                cursor="pointer"
                height="27px"
                width="25px"
                background={`url('${config.imgPath}/d/46272/1663580357-message.svg')`}
                backgroundRepeat="no-repeat"
                _hover={{
                  background: `url('${config.imgPath}/d/46272/1663580355-message1.svg')`,
                }}
              ></Text>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
