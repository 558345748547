import { Box, Link, Text } from "@chakra-ui/react";
import LazyLoadImageComponent from "@components/UI/LazyLoadImageComponent";
import config from "@config";
import parser from 'html-react-parser';
import { render } from 'datocms-structured-text-to-html-string';
import StructuredTextParser from '@ila/components/Utility/StructuredTextParser';

const ZigZag = ({ zigZagContent, languageData, region }) => {
    // console.log("zig zag cntent", zigZagContent);
    return (
        <>
            <Box
                padding={{ base: '0 0 86px', md: '0 2.2% 75px', lg: '0 2.2% 135px' }}
                textAlign="center"
                marginTop={"-1px"}
                border={"none"}
                background={`url('${config.staticPath}/assets/images/home-outreach-texture.jpg') no-repeat`}
                clipPath={{
                    base: 'polygon(0 0, 100% 0, 100% 97%, 0% 100%)',
                    md: 'polygon(0 0, 100% 0, 100% 95%, 0% 100%)',
                    lg: 'polygon(0 0, 100% 0, 100% 95%, 0% 100%)',
                }}
            >
                <Text
                    fontSize={{ base: '18px', lg: '24px' }}
                    lineHeight="1.42"
                    color="#faf7f0"
                    padding={{ base: '40px 6px 0', md: '80px 0 0' }}
                    textAlign="center"
                    fontFamily="FedraSansStd-book-i"
                    w={{ base: 'unset', sm: '80%', md: 'unset' }}
                    m={{ base: 'unset', sm: '0 auto', md: 'unset' }}
                // dangerouslySetInnerHTML={htmlContentOutreach()}
                >
                    {/* {parser(zigZagContent?.sectionContent[0].htmlContent ? zigZagContent?.sectionContent[0].htmlContent : '')
                    } */}
                    <StructuredTextParser
                        str={render(zigZagContent?.sectionContent[0].body)}
                        region={region}
                        textAlign="center"
                        fontSize={{ base: '18px', lg: '24px' }}
                        maxW={{ base: 'unset', sm: '80%', md: '90%' }}
                        color={"#faf7f0"}
                        // sectionContent={zigZagContent?.sectionContent[0]}
                        lang={languageData}
                        // textAlign={zigZagContent?.sectionContent[0].style}
                        />
                </Text>
                {/* article blocks  */}
                <Box
                    width={{ base: '90%', sm: '80%', md: '98%', lg: '91%' }}
                    margin={{ base: '40px auto 0', md: '80px auto 0' }}
                >
                    <Box
                        textAlign="left"
                        display="flex"
                        justifyContent="left"
                        flexDir={{ base: 'column', md: 'row' }}
                        m="12px 0 0 0"
                    >
                        <Box
                            m={{ base: '0 22px 0 0', md: '0 30px 0 0' }}
                            w={{ base: '90%', md: '48%', lg: '33%' }}
                        >
                            <LazyLoadImageComponent

                                alt={zigZagContent?.sectionContent[1]?.image?.alt}
                                title={zigZagContent?.sectionContent[1]?.image?.title}
                                src={zigZagContent?.sectionContent[1]?.image?.url}
                            />
                        </Box>
                        <Box w={{ base: '98%', md: '50%' }} m="0" color="#faf7f0">
                            <Box
                                as="h2"
                                fontSize={{ base: '30px', md: '45px', xl: '54px' }}
                                lineHeight="0.7"
                                mt={{ base: '20px', md: '0' }}
                            >
                                {zigZagContent?.sectionContent[1].title}
                                <Box
                                    fontSize={{ base: '24px', md: '20px', lg: '24px' }}
                                    lineHeight="1.7"
                                    color="#ffd182"
                                >
                                    {zigZagContent?.sectionContent[1].subHeading}
                                </Box>
                            </Box>
                            <Text m="15px 0 0 0">
                                {zigZagContent?.sectionContent[1].description}
                            </Text>
                            <Link
                                fontSize="13px"
                                border="none"
                                fontWeight="500"
                                padding="6px 12px"
                                borderRadius="3px"
                                height="auto"
                                marginTop="10px"
                                display="inline-block"
                                lineHeight="unset"
                                background="#cf4520"
                                href={
                                    '/' + region + '/' + languageData + zigZagContent?.sectionContent[1].ctaLink
                                }
                                // href={
                                //     'https://isha.sadhguru.org/global/en' + zigZagContent?.sectionContent[1]?.ctaLink
                                // }
                                _hover={{
                                    border: 'none',
                                    background: '#000054',
                                }}
                            >
                                {zigZagContent?.sectionContent[1].ctaName}
                            </Link>
                        </Box>
                    </Box>
                </Box>
                {/* Article box flip  */}
                {zigZagContent?.sectionContent[2] && (
                    <Box
                        width={{ base: '90%', sm: '80%', md: '98%', lg: '91%' }}
                        margin={{ base: '70px auto 0', md: '80px auto 0' }}
                    >
                        <Box
                            textAlign={{ base: 'right', sm: 'right', md: 'right' }}
                            display="flex"
                            justifyContent={{ base: 'left', sm: 'right', md: 'right' }}
                            m="12px 0 0 0"
                            flexFlow={{ base: 'column', md: 'row-reverse' }}
                        >
                            <Box
                                m="0 30px 0 0"
                                textAlign={{ base: 'unset', sm: 'right' }}
                                w={{ base: '90%', md: '48%', lg: '33%' }}
                            >
                                <LazyLoadImageComponent
                                    alt={zigZagContent?.sectionContent[2]?.image?.alt}
                                    title={zigZagContent?.sectionContent[2]?.image?.title}
                                    src={zigZagContent?.sectionContent[2]?.image?.url}
                                />
                            </Box>
                            <Box
                                w={{ base: '98%', md: '50%' }}
                                m="0 30px 0 0"
                                color="#faf7f0"
                            >
                                <Box
                                    as="h2"
                                    fontSize={{ base: '30px', md: '45px', xl: '54px' }}
                                    lineHeight="0.7"
                                    mt={{ base: '35px', md: '0' }}
                                >
                                    {zigZagContent?.sectionContent[2].title}
                                    <Box fontSize="24px" lineHeight="1.7" color="#ffd182">
                                        {zigZagContent?.sectionContent[2].subHeading}
                                    </Box>
                                </Box>
                                <Text m="15px 0 0 0">
                                    {
                                        zigZagContent?.sectionContent[2]
                                            .description
                                    }
                                </Text>
                                <Link
                                    float="right"
                                    fontSize="13px"
                                    border="none"
                                    fontWeight="500"
                                    padding="6px 12px"
                                    borderRadius="3px"
                                    height="auto"
                                    marginTop="10px"
                                    display="inline-block"
                                    lineHeight="unset"
                                    background="#cf4520"
                                    href={
                                        '/' + region + '/' + languageData + zigZagContent?.sectionContent[2].ctaLink
                                    }
                                    // href={
                                    //     'https://isha.sadhguru.org/global/en' + zigZagContent?.sectionContent[2]?.ctaLink
                                    // }
                                    _hover={{
                                        border: 'none',
                                        background: '#000054',
                                    }}
                                >
                                    {zigZagContent?.sectionContent[2].ctaName}
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}

export default ZigZag