/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MediaArticleCard from '@components/Card/MediaArticleCard';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */

/**
 * Renders the Related Stories component
 * @method
 * @param {Object} data -  Related Stories Data
 * @param {Object} titleObj - Title data
 * @param {Object} lang - Current User Language
 * @param {Boolean} isShareEnabled - Share Icon
 * @returns {ReactElement} Related stories component
 */
export default function RelatedStories(section, titleObj, isShareEnabled) {
  const data = section.section[0].cards;
  // console.log('related', data);
  return data?.length > 0 ? (
    <Box
      as="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      pb="30px"
      background={{
        base: 'linear-gradient(to bottom, #ffffff 1%, #ccc3b0 97%)',
        lg: `url('${config.staticPath}/assets/images/ornaments-leaves.svg'), linear-gradient(to bottom, #ffffff 1%, #ccc3b0 97%)`,
      }}
      backgroundRepeat={{ base: 'no-repeat', lg: 'no-repeat, no-repeat' }}
      backgroundSize={{ base: 'cover', lg: '500px, cover' }}
      backgroundPosition={{ base: 'center', lg: '0 80px, center' }}
      mt={{ md: '0', sm: '0' }}
    >
      <Box float="left" width="100%" mt={{ base: '62px', md: '60px' }}>
        <Box
          float="left"
          width="100%"
          fontFamily="'FedraSansStd-A-medium', 'sans-serif'"
          fontSize={{ base: '24px', md: '30px', lg: '30px' }}
          lineHeight="1.13"
          textAlign="center"
          color="#02026c"
        >
          {section.section[0]?.title || 'Related Content'}
          {/* Related Content For {contentSection} Detail */}
        </Box>
        <Box float="left" width="250px" m="5px calc((100% - 250px) / 2) 0">
          <LazyLoadImageComponent
            alt="divider"
            title="Divider"
            src={`${config.staticPath}/assets/images/divider2.svg`}
            float="left"
            width="100%"
            height="29px"
          />
        </Box>
      </Box>
      <Box
        mt="45px"
        display="flex"
        maxWidth="1170px"
        w="full"
        flexWrap="wrap"
        justifyContent="center"
        gridGap={{ base: '15px', sm: '30px' }}
      >
        {/* <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 1100: 3 }}
        >
          <Masonry columnsCount={3}> */}
        {data?.map((post, indx) => {
          return (
            // <Box
            //   width={{ base: '302px', sm: '330px', lg: '370px' }}
            //   display="flex"
            // >

            <MediaArticleCard
              key={indx}
              articleposition={indx}
              {...post}
              isShareEnabled={isShareEnabled}
            />

            // </Box>
          );
        })}
        {/* </Masonry>
        </ResponsiveMasonry> */}
      </Box>
    </Box>
  ) : null;
}
