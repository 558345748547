/**
 * Documentation
 *
 * @description use Intersection Observer is a custom hook to utilize IntersectionObserver
 *
 * @param {HTMLElement}   ref
 * @param {string}        rootMargin
 * @param {array}         threshold
 *
 * @returns {string} recordingType
 *
 * @returns{boolean} is Intersecting is boolean
 */
/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
/* Internal Imports */
/* Style */
/* Services */
/* Components */

export const useIntersectionObserver = ({
  ref,
  rootMargin = `0px`,
  threshold = [0.0],
}) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIntersecting(entry.isIntersecting);
          observer.unobserve(ref.current);
        }
      },
      {
        rootMargin,
        threshold,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};

export default useIntersectionObserver;
