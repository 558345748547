/* Built In Imports */
/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextContent from '@components/UI/StructuredTextContent';

/* Services */

/**
 * Renders the fmf widget component
 *
 * @param fmfData.fmfData
 * @param {object} fmfData - content from api
 * @param {object} languageData - language
 * @param {string} region - region and country
 * @param fmfData.languageData
 * @param fmfData.region
 * @returns {ReactElement} fmf widget component
 */
const FmfWidget = ({ fmfData, languageData, region }) => {
  //  consoleLog(result);

  const checkLink = url => {
    let finalLink = url;
    url.indexOf('http') < 0 && (finalLink = `/${region}/${languageData}${url}`);
    return finalLink;
  };

  return (
    <Flex
      w="100%"
      flexFlow={{ base: 'column-reverse', md: 'column-reverse', lg: 'row' }}
      flexWrap="wrap"
      pos="relative"
      marginTop="42px"
    >
      <Box
        w={{
          base: '100%',
          sm: '100%',
          md: '96%',
          lg: '50%',
        }}
        m={{ base: 'unset', md: '0 auto', lg: 'unset' }}
      >
        <Box
          textAlign="right"
          marginRight={{ base: '5px', lg: '30px' }}
          paddingLeft={{ base: '3%', lg: '10%' }}
        >
          <Text
            fontSize={{ base: '26px', sm: '26px', md: '26px', lg: '26px' }}
            paddingTop="10px"
            color="#28231e"
            lineHeight={{
              base: '1.3',
              sm: '1.3',
              md: '1.73',
              lg: '1.73',
            }}
          >
            {fmfData.title}
          </Text>
          {fmfData.subHeading ? (
            <Text
              fontFamily="FedraSerifAStdBook"
              fontSize={{
                base: '40px',
                sm: '40px',
                md: '45px',
                lg: '45px',
              }}
              color="#28231e"
              lineHeight={{
                base: '1.0',
                sm: '1.0',
                md: '1.0',
                lg: '1.0',
              }}
            >
              {fmfData.subHeading}
            </Text>
          ) : null}
          <Text
            fontFamily="FedraSansStd-book"
            fontSize={{
              base: '16px ',
              sm: '16px ',
              md: '18px',
              lg: '18px',
            }}
            lineHeight={{
              base: '1.5',
              sm: '1.5 ',
              md: '1.33',
              lg: '1.33',
            }}
            textAlign="right"
            color="#676055"
            padding="5px 0 20px"
          >
            {StructuredTextContent(fmfData.wide_card_description)}
          </Text>
          <Box margin={{ base: '2px 0 0 0', lg: '2px 0 0 0' }}>
            <Link
              href={checkLink(fmfData.ctaLink)}
              background="#cf4520"
              borderRadius="3px"
              color="#faf7f0"
              fontWeight="500"
              float="right"
              fontSize={{ base: '18px', sm: '18px', md: '18px' }}
              mt="10px"
              padding={{
                base: '7px 19px',
                sm: '7px 19px',
                lg: '10px 36px',
              }}
              display="table-cell"
              border="none"
              _hover={{
                border: 'none',
                color: '#ffffff',
                background: '#000054',
              }}
              _active={{
                border: 'none',
                background: '#000054',
              }}
            >
              {fmfData.ctaName}
            </Link>
          </Box>
        </Box>
      </Box>

      <Box
        w={{
          base: '100%',
          sm: '100%',
          md: '94%',
          lg: '50%',
        }}
        m={{ base: 'unset', md: '0 auto', lg: 'unset' }}
      >
        <Box
          w={{ base: '98%', md: '100%', lg: '98%' }}
          backgroundColor="#c98c31;"
          minH={{ base: '192px', md: '346px' }}
        >
          <LazyLoadImageComponent src={fmfData?.image?.url} alt="" title="" />
        </Box>
      </Box>
    </Flex>
  );
};

export default FmfWidget;
