/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */

/**
 * Returns Flower Divider Component
 *
 * @param {string} p - Padding
 * @param {string} mt - Margin Top
 * @returns Flower Divider Component
 */
export default function FlowerDivider({ p, mt, transfrm }) {
  return (
    <Box mt={mt || '0'}>
      <Box
        p={{ base: p || '0 0 14px 0', md: p || '60px 0 14px 0' }}
        textAlign="center"
      >
        <LazyLoadImageComponent
          alt="Divider Image"
          title="Divider Image"
          src={`${config.staticPath}/d/46272/1684481897-flower-divider-horizontal-orange.svg`}
          transform={transfrm || 'rotate(180deg'}
          w={{ base: '58%', md: 'auto' }}
        />
      </Box>
    </Box>
  );
}
