/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import MediaArticleCard from '@components/Card/MediaArticleCard';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Renders Latest Wisdom From Blog component
 * @param {Object} latestWisdom - content from api
 * @param {string} lang - language
 * @param {string} region - country
 * @returns {ReactElement} - Latest Wisdom From Blog Component
 */
const LatestWisdomFromBlog = ({ latestWisdom, region, lang }) => {
  let secData = latestWisdom?.sectionContent[0];
  return (
    <Box
      display="flex"
      gridGap="30px"
      margin={{ base: '46px auto 0', lg: '120px auto 0', xl: '46px auto 0' }}
      width={{ base: '91%', md: '92%' }}
      flexFlow={{ base: 'column', sm: 'unset', md: 'row', lg: 'row' }}
      flexWrap={{ sm: 'wrap !important', lg: 'nowrap !important' }}
      alignItems={{ base: 'center', sm: 'flex-start', md: 'flex-start' }}
      justifyContent={{ base: 'center', sm: 'space-around' }}
      textAlign="left"
    >
      <Box
        w={{ base: '93%', md: '100%', lg: '31.8%' }}
        margin={{
          base: '0 0 30px 0',
          md: '0 20px 15px 0',
          lg: '0 30px 15px 0',
        }}
        padding={{ base: '0', md: '0 15px' }}
        minHeight={{ base: 'unset', lg: '450px' }}
        fontSize={{ base: '30px', md: '45px' }}
        color="#d4c9b4"
        background={{
          base: 'none',
          md: `url('${config.staticPath}/assets/images/illustration-wisdom-landing-2.svg') no-repeat center right`,
          lg: `url('${config.staticPath}/assets/images/illustration-wisdom-landing-2.svg') no-repeat bottom center`,
          xl: `url('${config.staticPath}/assets/images/illustration-wisdom-landing-2.svg') no-repeat bottom right`,
        }}
        backgroundSize={{ base: 'unset', md: '26%', lg: '75%', xl: 'unset' }}
      >
        <Text lineHeight="1.17">{secData?.title}</Text>
        <NextLink
          href={refineUrlDomain('/wisdom', region, lang)}
          prefetch={false}
          passHref
          legacyBehavior
        >
          <Link>
            <Box
              color="#cf4824"
              fontSize="16px"
              float={{ base: 'left', md: 'left', lg: 'right' }}
              paddingTop="13px"
              fontWeight="600"
              cursor="pointer"
            >
              see all articles
            </Box>
          </Link>
        </NextLink>
      </Box>
      {/* latest wisdom cards */}
      {secData?.cards?.length > 0 &&
        secData.cards.map((item, index) => {
          return (
            <Box
              w={{ base: '100%', md: '50%', lg: '33%' }}
              key={index}
              padding={{ base: '0', md: '0 0 20px 0' }}
              background="#ffffff"
            >
              <MediaArticleCard
                title={item.title}
                summary={item.summary}
                contentType={item.contentType}
                uuid={item.uuid}
                createdAt={item.createdAt}
                url={item.url}
                cardImage={item.cardImage}
                isShareEnabled={true}
                key={nanoid()}
              />
            </Box>
          );
        })}
    </Box>
  );
};

export default LatestWisdomFromBlog;
