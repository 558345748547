/* Built In Imports */
import Head from 'next/head';
import { useRouter } from 'next/router';

/* External Imports */
/* Internal Imports */
/* Components */
import config from '@config';
import { getUtmParameters } from '@utils/index.js';

/* Services */

/**
 * Renders the Head component
 *
 * @param {object} data - content from api
 * @param {object} availableLocals - region and country
 * @param {string} script - <script> tag in header
 * @param {object} pageconfig - page configuration
 * @returns {ReactElement} Head component
 */

const HeadComponent = ({ data, availableLocals, script, pageconfig }) => {
  // const [fonts, setFonts] = useState([]);
  const router = useRouter();
  const { language, others } = router.query;
  const isMom = others?.indexOf('miracle-of-mind') > -1;
  const seoFieldsHandler = () => {
    if (data && data?.length && typeof data !== 'string') {
      return data.map(d => {
        const newTAG = [];
        let name = '';
        let content = '';
        let property = '';
        let href = '';
        let rel = '';
        if (d.tag === 'meta') {
          Object.entries(d.attributes).map(([k, v]) => {
            name = k.trim() === 'name' ? v : name;
            content = k.trim() === 'content' ? v : content;
            property = k.trim() === 'property' ? v : property;
            if (content !== '' && name !== '') {
              newTAG.push(<meta name={name} content={content} />);
            } else if (content !== '' && property !== '') {
              newTAG.push(
                <meta key={v} content={content} property={property} />
              );
            }
          });
        }
        if (d.tag === 'link' && d.attributes !== null) {
          Object.entries(d.attributes).map(([key, value]) => {
            href = key.trim() === 'href' ? value : href;
            rel = key.trim() === 'rel' ? value : rel;
            if (href !== '' && rel !== '') {
              if (rel === 'canonical' && !href.startsWith('http')) {
                href = `${config.BASE_PATH}${href}`;
              }
              newTAG.push(<link key={value} href={href} rel={rel} />);
            }
          });
        }
        if (pageconfig?.metarobots === 'noindex') {
          newTAG.push(<meta name="robots" content={pageconfig?.metarobots} />);
        }
        if (pageconfig?.metarobots === 'notranslate') {
          newTAG.push(<meta name="robots" content={pageconfig?.metarobots} />);
        }
        if (d.tag === 'title') {
          newTAG.push(<title>{d.content}</title>);
        }
        return newTAG;
      });
    }
  };

  const availableLocalsHandler = () => {
    const url = router.asPath;
    const { region, language } = router.query;
    if (
      (url.includes('/sadhguru-satsang') ||
        url === `/${region}/${language}` ||
        url === `/${region}/${language}/`) &&
      availableLocals &&
      availableLocals.length
    ) {
      if (pageconfig?.href !== false) {
        return availableLocals.map((l, v) => {
          const separateLocal = l.locale?.split('_');
          const availLocals = separateLocal[1]
            ? `${separateLocal[0]?.toLowerCase()}-${separateLocal[1]?.toLowerCase()}`
            : `${separateLocal[0]?.toLowerCase()}`;
          const url = `${config.BASE_PATH}${l.value}`;
          return (
            <link
              key={v}
              rel="alternate"
              hrefLang={availLocals === 'en' ? 'x-default' : availLocals}
              href={url}
            />
          );
        });
      }
    }
  };

  const updateUtmParameters = () => {
    return getUtmParameters(router.asPath);
  };

  const setScript = () => {
    if (script) {
      return (
        <script
          dangerouslySetInnerHTML={{
            __html: script,
          }}
        ></script>
      );
    }
  };

  const setCSSLink = () => {
    setTimeout(function () {
      if (typeof document !== 'undefined') {
        let fileRef = document.createElement(`link`);
        fileRef.rel = `stylesheet`;
        fileRef.type = `text/css`;
        fileRef.href = `${config.staticPath}/assets/fonts/mukta-fonts.css?family=FedraSerifAStdBook`;
        document.getElementsByTagName(`head`)[0].appendChild(fileRef);
      }
    }, 1800);
    return null;
  };

  return (
    <Head>
      <title>Official Website of Sadhguru, Isha Foundation | India</title>
      {updateUtmParameters()}
      {setScript()}
      {availableLocalsHandler()}
      {seoFieldsHandler()}
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=yes"
      />
      <meta name="MobileOptimized" content="width" />
      <meta name="HandheldFriendly" content="true" />
      <link
        rel="icon"
        href={`${config.imgPath}/d/46272/1720450736-favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        href={`${config.imgPath}/d/46272/1690792477-isha_logo_black.svg`}
      />
      <meta name="theme-color" content="#e4ded4" />
      {setCSSLink()}
      {language === 'hi' && (
        <link
          rel="stylesheet"
          href={`${config.staticPath}/assets/fonts/mukta-fonts.css?family=Mukta`}
        />
      )}
      {isMom && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Geist|Trirong"
        />
      )}
      {language === 'ta' && (
        <link
          rel="stylesheet"
          href={`${config.staticPath}/assets/fonts/mukta-fonts.css?family=MuktaMalar`}
        />
      )}
      {language === 'ta' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@400;500;600&display=swap"
        />
      )}
      {language === 'te' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@400;500;600&display=swap"
        />
      )}
      {language === 'ml' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@400;500;600&display=swap"
        />
      )}
      {language === 'bn' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Bangla:wght@400;500;600&display=swap"
        />
      )}
      {language === 'gu' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@400;500;600&display=swap"
        />
      )}
      {language === 'kn' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Kannada:wght@400;500;600&display=swap"
        />
      )}
      {language === 'hi' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@400;500;600&display=swap"
        />
      )}
      {language === 'zh-tw' ||
        (language === 'zh' && (
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600&display=swap"
          />
        ))}
      {language === 'ar' && (
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&display=swap"
        />
      )}
    </Head>
  );
};

export default HeadComponent;
