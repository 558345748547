/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Internal Imports */
/* Components */
import LikeBookmark from '@components/Utility/LikeBookmark';
import ShareIcon from '@components/Utility/ShareIcon';

/* Services */

/**
 * This component about Quote of the day
 * Renders The Daily Quote Component
 * @param {Object} dailyQuote - Daily quote data
 * @return {ReactElement} daily Quote Component
 */
const QuoteOfTheDay = ({ dailyQuote }) => {
  const [quote, setQuote] = useState(dailyQuote);
  const [quoteCard, setQuoteCard] = useState([]);
  const [updatedCard, setUpdatedCard] = useState([]);

  useEffect(() => {
    if (dailyQuote.cards && dailyQuote.cards.length > 0) {
      const card = dailyQuote.cards.map(el => {
        return { ...el, isLiked: false, isBookmarked: false };
      });
      let quote = updatedCard && updatedCard.length > 0 ? updatedCard : card;
      setQuoteCard(quote);
    }
  }, [updatedCard, dailyQuote.cards]);

  const updateCards = (id, flag) => {
    const cards = quoteCard.map(el => {
      if (id && flag && el.uuid === id) {
        return {
          ...el,
          isLiked: flag === 'likes' ? !el.isLiked : el.isLiked,
          isBookmarked:
            flag === 'bookmarks' ? !el.isBookmarked : el.isBookmarked,
        };
      }
      // return {
      //   ...el,
      //   isLiked: likes && likes.find((like) => like === el.uuid) ? true : false,
      //   isBookmarked:
      //     bookmarks && bookmarks.find((bookmark) => bookmark === el.uuid)
      //       ? true
      //       : false,
      // };
    });
    setUpdatedCard(cards);
  };

  return quoteCard ? (
    <>
      <Box margin="0 auto" textAlign="center">
        {quoteCard &&
          quoteCard.length > 0 &&
          quoteCard.map((quotes, index) => {
            if (index === 0) {
              return (
                <>
                  <Box key={index} padding="0 0 20px 0">
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      minH={{ base: '203px', md: '220px' }}
                    >
                      <LazyLoadImageComponent
                        width={{ base: '100%', md: '60%', lg: '41%' }}
                        src={quotes.cardImage?.url}
                        alt={quotes.cardImage?.alt}
                        title={quotes.cardImage?.title}
                      />
                    </Box>
                    <Box
                      padding={{ base: '10px 51px', md: '10px 78px' }}
                      fontSize={{ base: '20px', md: '27px' }}
                      lineHeight="1.44"
                      color="#000054"
                    >
                      <Text> {quotes.summary} </Text>
                    </Box>
                    <Text padding="10px 0" fontSize="16px" color="#8a8173">
                      {dailyQuote.title}
                      {''}
                      {quotes.title}
                    </Text>

                    {/* <Text
                    padding={{ base: '10px 70px', md: '10px 0' }}
                    fontSize="14px"
                    color="#8a8173"
                  >
                    {dailyQuote.wisdom_card_description}
                  </Text> */}
                  </Box>

                  <Box pos="relative" display="flex" justifyContent="center">
                    <LikeBookmark
                      id={quotes.uuid}
                      isLiked={quotes.isLiked}
                      isBookmarked={quotes.isBookmarked}
                      updateCards={updateCards}
                      uiFlex="row"
                    />
                    <ShareIcon url={quotes.url} />
                  </Box>
                </>
              );
            }
          })}
      </Box>
    </>
  ) : (
    ''
  );
};

export default QuoteOfTheDay;
