/* Built In Imports */
/* External Imports */
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 * This component about Inner Engineering Online program
 * @param {Object} innerEngData - content from api
 * @param {Object} languageData - language
 * @param {string} region - region and country
 * @return {ReactElement} - Inner Engineering Online Home Component
 */

const InnerEngHome = ({ innerEngData, languageData, region }) => {
  return (
    <>
      <Flex
        w="100%"
        flexFlow={{ base: 'column-reverse', md: 'row' }}
        flexWrap="wrap"
        pos="relative"
        marginTop="20px"
      >
        <Box
          w={{
            base: '100%',
            sm: '100%',
            md: '77%',
          }}
          marginTop={{ md: '30px' }}
          marginLeft={{ md: '20px', lg: '20px' }}
          boxShadow="0 12px 15px -6px rgb(0 0 0 / 30%)"
          _hover={{
            boxShadow: '0 0 48px 0 rgb(0 0 0 / 25%)',
          }}
        >
          <Box
            backgroundColor="#000054 !important;"
            minH={{ base: '196px', md: '583px' }}
          >
            <Image
              alt={innerEngData.content[0].image?.alt}
              title={innerEngData.content[0].image?.title}
              src={innerEngData.content[0].image?.url}
              clipPath="polygon(0 0, 100% 0, 100% 100%, 0% 90%)"
            />
            <Box>
              <Text
                fontFamily="FedraSansStd-A-medium"
                fontSize={{ base: '20px', sm: '24px', md: '30px' }}
                lineHeight={{ base: '1.08px', sm: 'unset', md: 'unset' }}
                textAlign="left"
                color="#ffffff"
                padding={{
                  base: '40px 20px',
                  md: '5px 25px',
                  lg: '40px 100px',
                }}
                verticalAlign="middle"
              >
                {innerEngData.content[0].title}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text
              fontFamily="FedraSansStd-book"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
              color="#464038"
              padding={{
                base: '40px 20px',
                md: '5px 25px',
                lg: '40px 100px',
              }}
              backgroundColor="#FFFFFF !important;"
            >
              <Box as="span" className="truncate-text lines-2">
                {' '}
                {innerEngData.content[0].summary}{' '}
              </Box>
              <Box as="div" paddingTop="15px">
                <Link
                  href={
                    '/' +
                    region +
                    '/' +
                    languageData +
                    innerEngData.content[0].urlAlias
                  }
                  fontFamily="FedraSansStd-medium"
                  paddingTop="20px"
                  color="#cf4520"
                  fontSize="16px"
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  <Box
                    as="span"
                    _hover={{
                      borderBottom: '1px solid #cf4520',
                    }}
                  >
                    Learn More
                  </Box>
                  <Image
                    alt=""
                    title=""
                    paddingLeft="15px !important;"
                    w="25px"
                    src={`${config.imgPath}/d/46272/1663645634-right-arrow-small-orange.svg`}
                  />
                </Link>
              </Box>
            </Text>
          </Box>
        </Box>

        <Box
          w={{
            base: '100%',
            sm: '100%',
            md: '40%',
            lg: '40%',
          }}
          /* display={{ base: 'none', md: 'block', lg: 'block', xl: 'block' }} */
        >
          <Text
            w={{
              base: '100%',
              sm: '100%',
              md: '80%',
              lg: '80%',
            }}
            fontSize={{ base: '25px', sm: '25px', md: '28px', xl: '42px' }}
            marginTop="34px"
            marginLeft={{ base: '5px', md: '30px' }}
            lineHeight="1.17"
            textAlign={{
              base: 'left',
            }}
            padding="0px !important"
            marginBottom={{ base: '65px', md: '35px' }}
            color="#28231e"
          >
            {innerEngData.content[0].title}
          </Text>
          <Box
            margin={{
              base: '0 auto 60px',
              md: '78px 0 0 30px',
              lg: '80px 0 0 30px',
            }}
          >
            <Link
              href={`/${region}/${languageData}/${innerEngData.registerCtaLink}`}
              background="#cf4520"
              borderRadius="3px"
              color="#faf7f0"
              fontWeight="500"
              fontSize={{ base: '16px', sm: '16px', md: '18px' }}
              padding={{ base: '7px 19px', sm: '7px 19px' }}
              display="table-cell"
              border="none"
              _hover={{
                border: 'none',
                color: '#ffffff',
                background: '#000054',
              }}
              _active={{
                border: 'none',
                background: '#000054',
              }}
            >
              {innerEngData.registerCtaName}
            </Link>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default InnerEngHome;
