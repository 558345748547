/* Built In Imports */
import NextLink from 'next/link';
import { useRef } from 'react';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import WrapLayout from '@Layout/WrapLayout';
import ComponentFoldWithBackground from '@components/Layout/ComponentFoldWithBackground';
import FlowerDivider from '@components/Layout/FlowerDivider';
import InnerEngHome from '@components/UI/InnerEngHome';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import LatestWisdomFromBlog from '@landing_pages/iso_HomePage/LatestWisdomFromBlog';
// import SadhguruExclusiveBanner from '@components/UI/SadhguruExclusiveBanner';
import HomeBannerMMM from '@components/HeaderBanner/HomeBannerMMM';
import QuoteOfTheDay from '@components/UI/QuoteOfTheDay';
import SubscribeContent from '@components/UI/Subscribe/SubscribeContent';
import { useIntersectionObserver } from '@components/UI/Subscribe/useIntersectionObserver';
import HeadComponent from '@components/Utility/HeadComponent';
import { refineUrlDomain } from '@components/Utility/utils';
import FmfWidget from '@components/Widgets/FmfWidget';
import config from '@config';
import ImageBannerAd from '@landing_pages/iso_HomePage/ImageBannerAd';
import IshaOutreach from '@landing_pages/iso_HomePage/IshaOutReach';
import MeetSadhguru from '@landing_pages/iso_HomePage/MeetSadhguru';
import RelatedStories from '@landing_pages/iso_HomePage/RelatedStories';
import TechnologiesForWellbeing from '@landing_pages/iso_HomePage/TechnologiesForWellbeing';
import ZigZag from '@landing_pages/iso_HomePage/ZigZag';
import { useMediaQuery } from 'react-responsive';

// const TwitterCard = dynamic(() => import('@components/Card/TwitterCard'));

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import {
  fetchFooterData,
  fetchHeaderMenuData,
  fetchHeaderTopMenuData,
  fetchHomePage,
  getHomePageLandingPaths,
} from 'services/commonService';

/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 *
 * @param root0
 * @param root0.region
 * @param root0.language
 * @param root0.error404Page
 * @param root0.headerMenuData
 * @param root0.footerData
 * @param root0.pageData
 */
export default function Language({
  region,
  language,
  error404Page,
  headerMenuData,
  footerData,
  pageData,
}) {
  const ref = useRef(null);
  const interSecting = useIntersectionObserver({
    ref,
    rootMargin: '500px',
  });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (error404Page) return <div>404 page</div>;

  let counter = 0;
  const renderUIContent = () => {
    // return pageData?.body[0].sectionContent?.map((section, i) => {
    // console.log('The page data is', pageData);
    // console.log('The page data is', pageData);
    return pageData?.body?.map((section, i) => {
      // console.log("section", section?.sectionId);
      switch (section.sectionId) {
        case uiSection.HOMEPAGE_WELLBEING:
          return (
            <>
              <ComponentFoldWithBackground key={i}>
                <HomeBannerMMM region={region} lang={language} />
                <FlowerDivider />
                {section.sectionContent[0]._modelApiKey === 'homepage_card' && (
                  <>
                    <TechnologiesForWellbeing
                      languageData={language}
                      region={region}
                      sectionData={section}
                    />
                  </>
                )}
              </ComponentFoldWithBackground>
            </>
          );
        case uiSection.LATEST_WISDOM:
          return (
            <Box>
              {section != undefined ? (
                <LatestWisdomFromBlog
                  latestWisdom={section}
                  region={region}
                  lang={language}
                  key={i}
                />
              ) : (
                ''
              )}
            </Box>
          );
        case uiSection.AD_BANNER1:
          return (
            <ImageBannerAd
              sectionContent={section}
              region={region}
              lang={language}
            />
          );
        case uiSection.SECTION_TITLE:
          break;
        case uiSection.HOMEPAGE_BANNER_CARD:
          counter = counter + 1;
          return AdSectionDisplay(i, counter);
        case uiSection.HOMEPAGE_WIDE_BANNER:
          return AdBannerDisplay(section?.sectionContent[0]);
        case uiSection.HOMEPAGE_PROGRAM:
          return (
            <Box
              background={{
                base: 'linear-gradient(to bottom, #ffffff 0%, #ffffff 6%, #ffffff 7%, #f1efe8 7%, #e3e0da 100%)',
                md: 'linear-gradient(to bottom, #ffffff 0%, #ffffff 2%, #ffffff 14%, #f1efe8 13%, #e3e0da 100%)',
                lg: 'linear-gradient(to bottom, #ffffff 0%, #ffffff 16%, #ffffff 17%, #f1efe8 17%, #e3e0da 100%)',
              }}
              borderBottom={{ base: 'unset', xl: 'solid 63px #e3e0da' }}
              padding={{ base: '1px 0 40px', xl: 'unset' }}
            >
              <Box width="91%" m="0 auto">
                {section != undefined ? (
                  <InnerEngHome
                    innerEngData={section.items[0]}
                    languageData={language}
                    region={region}
                    key={i}
                  />
                ) : (
                  ''
                )}
                {section.items[1] != undefined &&
                section.items[1].title != '' ? (
                  <FmfWidget
                    languageData={language}
                    region={region}
                    fmfData={section.items[1]}
                    key={i}
                  />
                ) : (
                  ''
                )}
              </Box>
            </Box>
          );
        case uiSection.SADHGURU_QUOTE:
          return (
            <Box
              display="flex"
              // justifyContent="space-between"
              justifyContent="center"
              flexFlow={{ base: 'column', md: 'column', lg: 'row' }}
              m={{ base: '20px 0 0 0', md: '115px 0 0 0' }}
              ref={ref}
              alignItems="center"
            >
              <Box
                w={{ base: '90%', lg: '66%' }}
                m={{ base: '0 auto', lg: 'unset' }}
                // borderRight={{ base: 'none', lg: 'solid 1px #eae3d4' }}
              >
                {section.sectionContent[0] != undefined ? (
                  <QuoteOfTheDay
                    dailyQuote={section.sectionContent[0]}
                    key={i}
                  />
                ) : (
                  ''
                )}
              </Box>
              {/* <Box
                  w={{ base: 'unset', md: '45%', lg: '30%' }}
                  m={{ base: 'unset', md: '0 auto', lg: 'unset' }}
                >
                  {interSecting && <TwitterCard />}
                </Box> */}
            </Box>
          );
        case uiSection.NEWSLETTER:
          return (
            <SubscribeContent
              contentType="home"
              region={region}
              contentObj={section}
              language={language}
            />
          );

        case uiSection.HOMEPAGE_OUTREACH_DONATION_CARD:
          return (
            <Box>
              {section.sectionContent != undefined ? (
                <IshaOutreach
                  outreachContent={section}
                  languageData={language}
                  region={'global'}
                  key={i}
                />
              ) : (
                ''
              )}
            </Box>
          );

        case uiSection.HOMEPAGE_ZIG_ZAG_SECTION:
          return (
            <ZigZag
              zigZagContent={section}
              languageData={language}
              region={region}
              key={i}
            />
          );
        // case uiSection.WISDOM_AND_BLOG_CONTENT_PLACEHOLDER:
        //   return (
        //     <Box>
        //       {section != undefined ? (
        //         <LatestWisdomFromBlog
        //           latestWisdom={section}
        //           region={region}
        //           lang={language}
        //           key={i}
        //         />
        //       ) : (
        //         ''
        //       )}
        //     </Box>
        //   );

        case uiSection.MEET_SADHGURU:
          return (
            <Box>
              {pageData?.meet_sadhguru?.results != 0 ? (
                <MeetSadhguru SecContent={section.sectionContent[0]} key={i} />
              ) : (
                ''
              )}
            </Box>
          );
        case uiSection.LATEST_FROM_BLOG:
          return (
            <RelatedStories
              section={section.sectionContent}
              titleObj={section}
              isShareEnabled={true}
              key={i}
            />
          );
        default:
          break;
      }
    });
  };

  const AdBannerDisplay = sectionContent => {
    // console.log('Sec is', sectionContent);
    return (
      <Box mx="auto">
        <Box
          textAlign="center"
          position="relative"
          mx="auto"
          my={10}
          width="100%"
          height="auto"
        >
          <NextLink
            href={
              sectionContent?.linkUrl
                ? refineUrlDomain(sectionContent?.linkUrl, region, language)
                : 'javascript:;'
            }
            passHref
            legacyBehavior
          >
            <Link
              target={
                sectionContent?.linkUrl?.indexOf('http') > -1 &&
                sectionContent?.linkUrl.indexOf(config.cdnPath) < 0
                  ? '_blank'
                  : ''
              }
              cursor="pointer"
            >
              <LazyLoadImageComponent
                alt={sectionContent?.image?.title}
                title={sectionContent?.image?.title}
                textAlign="center"
                src={
                  isMobile
                    ? sectionContent?.mobileImage?.url ||
                      sectionContent?.image?.url
                    : sectionContent?.image?.url
                }
                w="100%"
              />
            </Link>
          </NextLink>
        </Box>
      </Box>
    );
  };

  const AdSectionDisplay = (i, counter) => {
    switch (region) {
      case 'us':
      case 'ca':
        // if (counter === 1) {
        //   return (
        //     <Box>
        //       <Link
        //         href={
        //           'https://www.innerengineering.com/save-soil?utm_source=iso&utm_medium=referral&utm_campaign=save-soil&utm_content=iso-home-page-banner'
        //         }
        //         target="_blank"
        //       >
        //         <LazyLoadImageComponent
        //           display={{
        //             base: 'none !important',
        //             md: 'block !important',
        //             sm: 'none !important',
        //           }}
        //           src={`${config.imgPath}/d/46272/1677489871-ie2023_wide_banner.jpg`}
        //           alt="Inner Engineering Completion"
        //           title="Inner Engineering Completion"
        //         />
        //         <LazyLoadImageComponent
        //           display={{
        //             base: 'block !important',
        //             sm: 'block !important',
        //             md: 'none !important',
        //           }}
        //           src={`${config.imgPath}/d/46272/1677489877-ie2023_mobile_banner.jpg`}
        //           alt="Inner Engineering Completion"
        //           title="Inner Engineering Completion"
        //         />
        //       </Link>
        //     </Box>
        //   );
        // }
        return; //(
      //   <SadhguruExclusiveBanner
      //     region={region}
      //     language={language}
      //     key={i}
      //   />
      // );
      // case 'uk':
      //   return (
      //     <Box>
      //       <Link href={`${config.cdnPath}/karma/`} target="_blank">
      //         <LazyLoadImageComponent
      //           display={{
      //             base: 'none !important',
      //             md: 'block !important',
      //             sm: 'none !important',
      //           }}
      //           src={`${config.imgPath}/d/46272/1663649663-iso-karma-book-desktop.jpg`}
      //           // src="https://images.sadhguru.org/profiles/isha/themes/ishatheme/assets/iso-karma-book-desktop.jpg"
      //           alt="Karma"
      //         />
      //         <LazyLoadImageComponent
      //           display={{
      //             base: 'block !important',
      //             sm: 'block !important',
      //             md: 'none !important',
      //           }}
      //           src={`${config.imgPath}/d/46272/1663649723-iso-karma-book-mobile.jpg`}
      //           //src="https://images.sadhguru.org/profiles/isha/themes/ishatheme/assets/iso-karma-book-mobile.jpg"
      //           alt="Karma"
      //           title="Karma"
      //         />
      //       </Link>
      //     </Box>
      //   );
      default:
        return; //(
      //   <SadhguruExclusiveBanner
      //     key={i}
      //     region={region}
      //     language={language}
      //   />
      // );
    }
  };

  const uiSection = {
    HOMEPAGE_WELLBEING: 'tehcnologies-wellbeing',
    LATEST_WISDOM: 'latest-wisdom',
    AD_BANNER1: 'ad-banner-1',

    HOMEPAGE_CARD: 'homepage_card',
    HOMEPAGE_BANNER_CARD: 'homepage_banner_card',
    WISDOM_AND_BLOG_CONTENT_PLACEHOLDER: 'wisdom_and_blog_content_placeholder',
    HOMEPAGE_PROGRAM: 'homepage_program',
    HOMEPAGE_WIDE_CARD: 'homepage_wide_card',
    HOMEPAGE_LARGE_CARD: 'homepage_large_card',
    FRONTEND_PLACEHOLDER: 'frontend_placeholder',
    // HOMEPAGE_WIDE_BANNER: 'ad_image_banner',
    HOMEPAGE_WIDE_BANNER: 'ad-banner-2',
    // MEDIA_AND_PRESS_CONTENT: 'media_and_press_content',
    // HOMEPAGE_OUTREACH_DONATION_CARD: 'homepage_outreach_donation_card',
    HOMEPAGE_OUTREACH_DONATION_CARD: 'outreach-donation',
    // HOMEPAGE_ZIG_ZAG_SECTION: 'homepage_zig_zag_section',
    HOMEPAGE_ZIG_ZAG_SECTION: 'zig-zag',
    SADHGURU_QUOTE: 'sadhguru-quotes',
    // SADHGURU_QUOTE: 'wisdom_and_blog_content_placeholder_quote',
    MEET_SADHGURU: 'meet-sadhguru',
    HTML_BLOCK: 'html_block',
    NEWSLETTER: 'newsletter-subscription',
    SECTION_TITLE: 'section_title',
    // LATEST_FROM_BLOG: 'wisdom_and_blog_content_placeholder_blog',
    LATEST_FROM_BLOG: 'latest-from-blog',
  };

  return (
    <WrapLayout>
      <HeadComponent
        data={pageData?._seoMetaTags}
        availableLocals={pageData?.availableLocales}
        script = {""}
        pageconfig = {pageData?.pageConfig}
      />
      <Box as="section" className="page-wrap container-article" p="0">
        {renderUIContent()}
      </Box>
    </WrapLayout>
  );
}

// const getLocale = (region, language) => {
//   return region === 'global' ? language : `${language}-${region.toUpperCase()}`;
// };

const processHomeApi = pData => {
  let finalData = [];
  let sectionTitleIndex = -1;
  let outreachIndex = 0;
  let prevSection = '';

  forEach(pData, function (data, index) {
    switch (data._modelApiKey) {
      case 'section_title':
        sectionTitleIndex = finalData.length ? finalData.length - 1 : 0;
        break;
      case 'html_block':
        if (prevSection === 'homepage_outreach_donation_card') {
          finalData[outreachIndex].items.push(data);
        } else if (
          data.htmlContent.indexOf('omepage banner') > -1 ||
          data.htmlContent.indexOf('tml banner for ad') > -1
        ) {
          finalData.push({
            type: 'homepage_banner_card',
            items: [],
          });
        }
        break;
      case 'homepage_card':
      case 'testimonial':
        if (!finalData.length) {
          finalData = [
            {
              type: data._modelApiKey,
              items: [],
            },
          ];
        }
        finalData[sectionTitleIndex].items.push(data);
        break;
      case 'homepage_wide_card':
        forEach(finalData, function (innerSectionData) {
          innerSectionData.type === 'homepage_program' &&
            innerSectionData.items.push(data);
        });
        break;
      case 'homepage_outreach_donation_card':
      case 'homepage_zig_zag_section':
        if (!outreachIndex) {
          outreachIndex = finalData.length - 1;
          finalData[outreachIndex].type = 'homepage_outreach_donation_card';
        }
        finalData[outreachIndex].items.push(data);
        break;
      case 'wisdom_and_blog_content_placeholder': {
        let type = '';
        switch (data.title) {
          case 'Latest From Blog':
            type = 'wisdom_and_blog_content_placeholder_blog';
            break;
          case 'Sadhguru Quote - ':
            type = 'wisdom_and_blog_content_placeholder_quote';
            break;
          default:
            type = data._modelApiKey;
            break;
        }
        const arrDta = [data];
        pData[index + 1] && arrDta.push(pData[index + 1]);
        finalData.push({
          type: type,
          items: arrDta,
        });
        break;
      }
      default:
        finalData.push({
          type: data._modelApiKey,
          items: [data],
        });
        break;
    }
    prevSection = data._modelApiKey;
  });
  return finalData;
  //
};

export const getStaticPaths = async () => {
  const homePagePaths = config.USE_LOCAL_URL
    ? [
        '/in/en',
        '/in/ta',
        '/in/hi',
        '/au/en',
        '/ca/en',
        '/my/en',
        '/sg/en',
        '/uk/en',
        '/global/en',
        '/us/en',
      ]
    : await getHomePageLandingPaths('home_page', '/', [
        '/mahashivratri',
        '/sadhguru-academy',
        '/sacred-walks',
        '/linga-bhairavi',
      ]);
  return {
    paths: homePagePaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, language },
  req,
}) => {
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale(region, language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale(region, language),
    'isomainmenu'
  );
  const footerData = await fetchFooterData(
    getLocale(region, language),
    'isofooter'
  );
  const pageData = await fetchHomePage(region, language, {
    preview,
    data: previewData,
  });
  if (pageData) {
    pageData.content = processHomeApi(pageData.content);
  }

  const error404Page = false;
  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      error404Page,
      region: region,
      language: language,
      pageData: pageData || null,
      isPreview: preview ? true : false,
      footerData: footerData?.data || null,
    },
  };
};
