/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders Component Fold With Background Component
 *
 * @param {ReactElement} children - Child Elements
 * @returns Component Fold With Background Component
 */
export default function ComponentFoldWithBackground({ children }) {
  return (
    <Box>
      <Box
        pos="relative"
        background={{
          base: 'linear-gradient(to bottom, #EDEAE3 0%, #EDEAE3 2%, #f1efe8 3%, #e3e0da 100%)',
          md: 'linear-gradient(to bottom, #EDEAE3 0%, #EDEAE3 2%, #f1efe8 3%, #e3e0da 100%)',
          lg: 'linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 21%, rgba(204, 195, 176, 1) 100%)',
        }}
        clipPath="polygon(0 0, 100% 0, 100% 100%, 0 95%)"
        p="0 0 80px 0"
        maxW="1300px"
        m="0 auto"
      >
        {children}
      </Box>
    </Box>
  );
}
